//#region imports
import axios, { AxiosError } from "axios";
import { format } from "date-fns";
import isEqual from "lodash.isequal";
import { CalendarIcon } from "lucide-react";
import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  FieldValues,
  useController,
  UseControllerProps,
  useForm,
  useFormContext,
  useWatch,
} from "react-hook-form";
import { useDebouncedCallback } from "use-debounce";

import { QuestionsChangedAlert } from "@/components/QuestionsChangedAlert";
import { cn, dateFormat, handleFormErrors } from "@/lib/utils";
import {
  AlertType,
  Report,
  Sample,
  SampleResult,
  SampleRow,
  SampleStatus,
  TODO,
} from "@/types";

import { AsyncSelect } from "./AsyncSelect";
import { FormMultiUploadInput, MultiUpload } from "./FormMultiUploadInput";
import { FormScoreInput, ScoreInput } from "./FormScoreInput";
import { Icon } from "./Icon";
import { SampleForm, SampleSheet } from "./SampleForm";
import {
  Button,
  Calendar,
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
  Form,
  FormControl,
  FormDescription,
  FormFieldContext,
  FormItem,
  FormLabel,
  FormMessage,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  RadioGroup,
  RadioGroupItem,
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
  Separator,
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  Textarea,
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "./ui";
import { defaultErrorMessage, queryKeys } from "@/lib/constants";
import { useQueryClient } from "@tanstack/react-query";
import {
  FormSingleUploadInput,
  SingleUpload,
} from "@/components/FormSingleUploadInput";

//#endregion

type DisplayCondition = {
  fieldName: string;
  condition: "neq" | "eq" | "gt" | "exists" | "not_exists";
  value: unknown;
};

export type DynamicFormField = {
  name: string;
  label: React.ReactNode;
  description?: string;
  helperText?: string;
  tooltip?: string;
  defaultValue?: unknown;
  accessorKey: string;
  validation?: UseControllerProps["rules"];
  condition?: DisplayCondition;
  type:
    | { input: "DATE"; allowPastDates?: boolean }
    | { input: "TEXT" }
    | { input: "PASSWORD" }
    | { input: "NUMBER" }
    | { input: "TEXTAREA" }
    | RadioInput
    | SelectOption
    | MultiSelect
    | AsyncSelectOption
    | ScoreInput
    | MultiUpload
    | SingleUpload
    | AsbestosSampleInput;
  disabled?: boolean;
};

export type MultiSelect = {
  input: "MULTI_SELECT";
  options: { label: string; value: string }[];
};

export type RadioInput = {
  input: "RADIO";
  options: ({ label: string; value: string } & Record<string, unknown>)[];
  useObjectValue?: boolean;
  alert?: AlertType;
};

export type SelectOption = {
  input: "SELECT";
  options: { label: string; value: string }[];
  useObjectValue?: boolean;
  autofillConditions?: {
    triggerQuestion: string;
    triggerValues: unknown[];
    option: { label: string; value: string };
  }[];
  alert?: AlertType;
};

export type AsyncSelectOption = {
  input: "ASYNC_SELECT";
  endpoint: string;
  isMulti?: boolean;
  labelField: string;
  valueField: string;
  extraParams?: { fieldName: string; paramName: string }[];
  defaultParams?: Record<string, string>;
};

export type AsbestosSampleInput = {
  input: "ASBESTOS_SAMPLE";
  auditId: number;
  recordId: number;
  assetId: string;
};

export type DynamicFormFieldSet = {
  legend?: string;
  condition?: DisplayCondition;
  fields: DynamicFormField[];
};

export type DynamicFormInfo = DynamicFormFieldSet[];

type DynamicFormProps<T> = {
  formInfo: DynamicFormInfo;
  initialValues: T | undefined;
  onSubmit: (values: T) => Promise<void>;
  submitButtonText?: string;
  submitButtonStyle?: string;
  autoSave?: boolean;
};

export const DynamicForm = <T extends FieldValues>({
  formInfo,
  initialValues,
  onSubmit,
  submitButtonText = "Submit",
  submitButtonStyle,
  autoSave = false,
}: DynamicFormProps<T>) => {
  console.log("formInfo", formInfo);
  const form = useForm<T>({
    values: initialValues,
    shouldUnregister: false,
  });

  const handleSubmit = async (values: T) => {
    try {
      await onSubmit(values);
    } catch (error) {
      if (error instanceof AxiosError) {
        handleFormErrors(form as TODO, error);
      } else {
        form.setError("root", { message: defaultErrorMessage });
      }
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)} className="space-y-6">
        {formInfo.map((fieldSetInfo, index) => {
          return (
            <div key={String(index)}>
              <DynamicFieldSet fieldSetInfo={fieldSetInfo} />
            </div>
          );
        })}
        <FormMessage>{form.formState.errors.root?.message}</FormMessage>
        {!autoSave && (
          <div className="text-end">
            {!form.formState.isSubmitting ? (
              <Button type="submit" className={submitButtonStyle}>
                {submitButtonText}
              </Button>
            ) : (
              <Button type="button" className={submitButtonStyle} disabled>
                Please wait...
              </Button>
            )}
          </div>
        )}
      </form>
      {/* {import.meta.env.MODE === "development" && (
          <DevTool control={form.control} />
        )} */}
      {autoSave && <AutoSaver save={form.handleSubmit(onSubmit)} />}
    </Form>
  );
};
DynamicForm.displayName = "DynamicForm";

const AutoSaver = ({ save }: { save: () => Promise<void> }) => {
  const data = useWatch();
  const prevData = useRef<{ [x: string]: unknown } | null>(null);
  const debouncedSave = useDebouncedCallback(save, 1000);
  useEffect(() => {
    if (!prevData.current) {
      prevData.current = data;
      return;
    }
    if (!isEqual(data, prevData.current)) {
      prevData.current = data;
      debouncedSave();
    }
  }, [debouncedSave, data]);

  return null;
};

AutoSaver.displayName = "AutoSaver";

const DynamicFieldSet = ({
  fieldSetInfo,
}: {
  fieldSetInfo: DynamicFormFieldSet;
}) => {
  const { watch } = useFormContext();
  const fieldToWatch = fieldSetInfo.condition
    ? [fieldSetInfo.condition.fieldName]
    : [];
  const watchValues = watch(fieldToWatch);

  const shouldRender = () => {
    if (fieldSetInfo.condition) {
      switch (fieldSetInfo.condition.condition) {
        case "exists":
          return !!watchValues[0];
        case "not_exists":
          return !watchValues[0];
        case "eq":
          return isEqual(watchValues[0], fieldSetInfo.condition.value);
        default:
          return true;
      }
    }
    return true;
  };

  if (!shouldRender()) {
    return null;
  }

  return (
    <fieldset>
      <legend className="text-white mb-4 w-full bg-gray-800 px-1">
        {fieldSetInfo.legend}
      </legend>
      <div className="space-y-8">
        {fieldSetInfo.fields.map((fieldInfo) => (
          <DynamicField key={fieldInfo.name} fieldInfo={fieldInfo} />
        ))}
      </div>
    </fieldset>
  );
};

export const DynamicField = ({
  fieldInfo,
}: {
  fieldInfo: DynamicFormField;
}) => {
  const { watch } = useFormContext();
  const fieldToWatch = fieldInfo.condition
    ? [fieldInfo.condition.fieldName]
    : [];
  const watchValues = watch(fieldToWatch);

  const shouldRender = () => {
    if (fieldInfo.condition) {
      switch (fieldInfo.condition.condition) {
        case "exists":
          return !!watchValues[0];
        case "not_exists":
          return !watchValues[0];
        case "eq":
          return isEqual(watchValues[0], fieldInfo.condition.value);
        default:
          return true;
      }
    }
    return true;
  };

  if (!shouldRender()) {
    return null;
  }

  const { type, ...info } = fieldInfo;
  if (type.input === "MULTI_UPLOAD") {
    return <FormMultiUploadInput {...info} type={type} />;
  }
  if (type.input === "SINGLE_UPLOAD") {
    return <FormSingleUploadInput {...info} type={type} />;
  }
  if (type.input === "SCORE_INPUT") {
    return <FormScoreInput {...info} type={type} />;
  }
  if (type.input === "ASBESTOS_SAMPLE") {
    return <FormSampleInput {...info} type={type} />;
  }
  if (type.input === "MULTI_SELECT") {
    return <FormMultiSelect {...info} options={type.options} />;
  }
  if (type.input === "SELECT") {
    return <FormSelectInput {...info} type={type} />;
  }

  if (type.input === "RADIO") {
    return <FormRadioInput {...info} type={type} />;
  }

  if (type.input === "ASYNC_SELECT") {
    return <FormMultiAsyncSelect {...info} type={type} />;
  }

  if (type.input === "DATE") {
    return <FormDateInput {...info} allowPastDates={type.allowPastDates} />;
  }
  if (type.input === "TEXTAREA") {
    return <FormTextAreaInput {...info} />;
  }

  if (type.input === "TEXT") {
    return <FormTextInput {...info} />;
  }

  if (type.input === "PASSWORD") {
    return <FormPasswordInput {...info} />;
  }

  if (type.input === "NUMBER") {
    return <FormNumberInput {...info} />;
  }

  return null;
};

export const FormRadioInput = (
  props: Omit<DynamicFormField, "type"> & { type: RadioInput },
) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  const onValueChange = (value: string) => {
    if (!props.type.useObjectValue) {
      field.onChange(value);
      return;
    }
    const selectedOption = props.type.options.find((o) => o.value === value);
    field.onChange(selectedOption);
  };

  const value = !props.type.useObjectValue
    ? field.value
    : props.type.options.find((o) => o.value === field.value?.value)?.value;

  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <FormLabel className="text-body-text-default">{props.label}</FormLabel>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <div className="relative">
          <FormControl>
            <RadioGroup
              onValueChange={onValueChange}
              value={value}
              className="grid grid-cols-2 gap-4"
              disabled={field.disabled}
            >
              {props.type.options.map((option) => {
                return (
                  <FormItem key={option.value}>
                    <FormLabel className="flex items-center space-x-2 bg-secondary-50 rounded-md px-4 py-2 [&:has([data-state=checked])]:bg-primary-100">
                      <FormControl>
                        <RadioGroupItem value={option.value} />
                      </FormControl>
                      <span>{option.label}</span>
                    </FormLabel>
                  </FormItem>
                );
              })}
            </RadioGroup>
          </FormControl>
          {props.helperText && (
            <FormDescription>{props.helperText}</FormDescription>
          )}
        </div>
        {!value && props.type.alert && (
          <QuestionsChangedAlert alert={props.type.alert} />
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormSelectInput = (
  props: Omit<DynamicFormField, "type"> & { type: Omit<SelectOption, "input"> },
) => {
  const { watch } = useFormContext();
  const { field, fieldState } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
  });
  const uniqueQuestions = useMemo(
    () => [
      ...new Set(
        props.type.autofillConditions?.map((c) => c.triggerQuestion) || [],
      ),
    ],
    [props.type.autofillConditions],
  );
  const values = watch(uniqueQuestions);

  const { onChange, value: fieldValue } = field;
  const onValueChange = useCallback(
    (value: string) => {
      if (value === "$empty") {
        onChange("");
        return;
      }
      if (!props.type.useObjectValue) {
        onChange(value);
        return;
      }
      const selectedOption = props.type.options.find((o) => o.value === value);
      onChange(selectedOption || "");
    },
    [onChange, props.type.options, props.type.useObjectValue],
  );
  const value =
    field.value === ""
      ? ""
      : !props.type.useObjectValue
        ? fieldValue
        : props.type.options.find((o) => o.value === fieldValue?.value)?.value;
  // console.info({ formSelectInput: field, options, value });

  const [shouldAutofill, setShouldAutofill] = useState(!fieldValue);

  const autofillOption = useMemo(() => {
    if (props.type.autofillConditions) {
      for (let i = 0; i < uniqueQuestions.length; i += 1) {
        const matchingCondition = props.type.autofillConditions?.find(
          (conditon) =>
            conditon.triggerQuestion === uniqueQuestions[i] &&
            conditon.triggerValues.find((v) => isEqual(v, values[i])),
        );
        if (matchingCondition) {
          return matchingCondition.option;
        }
      }
    }
    return null;
  }, [props.type.autofillConditions, uniqueQuestions, values]);

  useEffect(() => {
    setShouldAutofill(isEqual(field.value?.value, autofillOption?.value));
  }, [autofillOption, field]);
  const isTouched = fieldState.isTouched;
  useEffect(() => {
    if (isTouched || !shouldAutofill) {
      return;
    }
    if (autofillOption && autofillOption.value !== value) {
      console.log(
        `autofilling ${props.name} with ${JSON.stringify(autofillOption)}`,
      );
      onValueChange(autofillOption.value);
    }
  }, [
    autofillOption,
    isTouched,
    onValueChange,
    props.name,
    shouldAutofill,
    value,
  ]);

  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <div className="relative">
          <Select
            onOpenChange={(open: boolean) => {
              if (!open) {
                field.onBlur();
              }
            }}
            onValueChange={onValueChange}
            value={value}
            disabled={props.disabled}
          >
            <FormControl>
              <SelectTrigger>
                <SelectValue
                  placeholder={
                    props.name === "26" ? "No override" : "Please Select"
                  }
                />
              </SelectTrigger>
            </FormControl>
            {props.helperText && (
              <FormDescription>{props.helperText}</FormDescription>
            )}
            <SelectContent>
              <SelectItem value="$empty">
                {props.name === "26" ? "No override" : "Please Select"}
              </SelectItem>
              {props.type.options.map((option) => (
                <SelectItem key={option.value} value={option.value}>
                  {option.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        {!value && props.type.alert && (
          <QuestionsChangedAlert alert={props.type.alert} />
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};
FormSelectInput.displayName = "FormSelectInput";

const FormMultiAsyncSelect = (
  props: Omit<DynamicFormField, "type"> & { type: AsyncSelectOption },
) => {
  const { watch } = useFormContext();
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  const fieldsToWatch =
    props.type.extraParams?.map((paramInfo) => paramInfo.fieldName) || [];
  const watchValues = watch(fieldsToWatch);

  const fetchOptions = (search: string, callback: TODO) => {
    const queryParams = props.type.extraParams?.reduce(
      (prev, paramInfo, currentIndex) => {
        return {
          ...prev,
          [paramInfo.paramName]: watchValues[currentIndex],
        };
      },
      {},
    );
    const queryParamsString = queryParams
      ? new URLSearchParams(queryParams).toString()
      : "";
    const defaultParamsString = props.type.defaultParams
      ? `${new URLSearchParams(props.type.defaultParams)}&`
      : "";
    axios
      .get(
        `${props.type.endpoint}?${defaultParamsString}search=${search}&${queryParamsString}`,
      )
      .then((response) => {
        let data = response.data;

        if (data?.data?.length > 0) {
          data = data.data;
        }

        callback(
          data.map((option: Record<string, unknown>) => ({
            ...option,
            label: option[props.type.labelField],
            value: option[props.type.valueField],
          })),
        );
      });
  };

  const debouncedFetchOptions = useDebouncedCallback(fetchOptions, 300);
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <AsyncSelect
            {...field}
            loadOptions={debouncedFetchOptions}
            isMulti={props.type.isMulti}
            //  key is used to force a re-render when the watchValues change which will cause the AsyncSelect to re-fetch the options
            key={JSON.stringify(watchValues)}
            isDisabled={field.disabled}
          />
        </FormControl>
        {props.helperText && (
          <FormDescription>{props.helperText}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

const FormTextAreaInput = (props: Omit<DynamicFormField, "type">) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <Textarea {...field} />
        </FormControl>
        {props.helperText && (
          <FormDescription>{props.helperText}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormTextInput = (props: Omit<DynamicFormField, "type">) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <Input {...field} />
        </FormControl>
        {props.helperText && (
          <FormDescription>{props.helperText}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormPasswordInput = (props: Omit<DynamicFormField, "type">) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <Input {...field} type="password" />
        </FormControl>
        {props.helperText && (
          <FormDescription>{props.helperText}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormNumberInput = (props: Omit<DynamicFormField, "type">) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <Input {...field} type="number" />
        </FormControl>
        {props.helperText && (
          <FormDescription>{props.helperText}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormDateInput = ({
  allowPastDates = true,
  ...props
}: Omit<DynamicFormField, "type"> & { allowPastDates?: boolean }) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });

  //  Used to catch when the field.value is a string and convert it to a Date
  const value =
    field.value && !(field.value instanceof Date)
      ? new Date(field.value)
      : field.value;

  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <Popover>
          <PopoverTrigger asChild>
            <FormControl>
              <Button
                variant={"secondary"}
                className={cn(
                  "w-full pl-3 text-left font-normal bg-white",
                  !value && "text-muted-foreground",
                )}
              >
                {value ? format(value, dateFormat) : <span>Pick a date</span>}
                <CalendarIcon className="ml-auto h-4 w-4 opacity-50" />
              </Button>
            </FormControl>
          </PopoverTrigger>
          <PopoverContent className="w-auto p-0" align="start">
            <Calendar
              mode="single"
              selected={value}
              onSelect={field.onChange}
              disabled={(date) => !allowPastDates && date < new Date()}
              initialFocus
            />
          </PopoverContent>
        </Popover>
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormMultiSelect = (
  props: Omit<DynamicFormField, "type"> & {
    options: { label: string; value: string }[];
  },
) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  const selection = (field.value as { label: string; value: string }[]) || [];
  const isAllSelected = selection.length === props.options.length;
  const display = isAllSelected
    ? "All"
    : selection.map((s) => s.label).join(", ") || "Choose";

  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <div className="flex items-center justify-between">
          <FormLabel className="text-body-text-default">
            {props.label}{" "}
            {props.validation ? <span className="text-red-600"> *</span> : null}
          </FormLabel>
          {props.tooltip && <FormTooltip content={props.tooltip} />}
        </div>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <FormControl>
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <FormControl>
                <Button
                  variant={"secondary"}
                  className={cn(
                    "w-full pl-3 text-left font-normal bg-white",
                    !field.value && "text-muted-foreground",
                  )}
                >
                  <span className="flex-1 truncate">{display}</span>
                  <Icon icon="fa-angles-up-down" className="opacity-50" />
                </Button>
              </FormControl>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="start">
              <DropdownMenuCheckboxItem
                checked={isAllSelected}
                onCheckedChange={(checked) => {
                  if (checked) {
                    field.onChange(props.options);
                    return;
                  }
                  field.onChange([]);
                }}
                onSelect={(e) => e.preventDefault()}
              >
                All
              </DropdownMenuCheckboxItem>
              {props.options.map((option) => {
                const optionIndexInSelection = selection.findIndex(
                  (s) => s.value === option.value,
                );
                const isChecked = optionIndexInSelection >= 0;
                return (
                  <DropdownMenuCheckboxItem
                    key={option.value}
                    checked={isChecked}
                    onSelect={(e) => e.preventDefault()}
                    onCheckedChange={() => {
                      if (!isChecked) {
                        field.onChange([...selection, option]);
                        return;
                      }
                      field.onChange([
                        ...selection.slice(0, optionIndexInSelection),
                        ...selection.slice(optionIndexInSelection + 1),
                      ]);
                    }}
                  >
                    {option.label}
                  </DropdownMenuCheckboxItem>
                );
              })}
            </DropdownMenuContent>
          </DropdownMenu>
        </FormControl>
        {props.helperText && (
          <FormDescription>{props.helperText}</FormDescription>
        )}
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const FormSampleInput = (
  props: Omit<DynamicFormField, "type"> & {
    type: AsbestosSampleInput;
  },
) => {
  const { field } = useController({
    name: props.name,
    rules: props.validation,
    disabled: props.disabled,
    defaultValue: props.defaultValue,
  });
  const queryClient = useQueryClient();
  const value = field.value as Sample | undefined | null;
  const [isEditing, setEditing] = useState(false);
  return (
    <FormFieldContext.Provider value={{ name: field.name }}>
      <FormItem className="flex flex-col">
        <FormLabel className="text-body-text-default">{props.label}</FormLabel>
        {props.description && (
          <FormDescription>{props.description}</FormDescription>
        )}
        <div>
          {value ? (
            <>
              <SampleCard
                sample={value}
                actions={{
                  onDelete: async () => {
                    await axios.delete(
                      route("api.samples.destroy", {
                        sample: value.sample_id,
                        inspection_id: props.type.recordId,
                      }),
                    );
                    field.onChange(null);
                  },
                  onEdit: () => {
                    setEditing(true);
                  },
                }}
              />
              <Sheet open={isEditing} onOpenChange={setEditing}>
                <SheetContent>
                  <SheetHeader>
                    <SheetTitle>Edit sample</SheetTitle>
                  </SheetHeader>
                  <Separator className="my-4" />
                  <SampleForm
                    auditId={props.type.auditId}
                    sample={
                      {
                        ...value,
                        as_sampleids: value.sample_id,
                      } as unknown as SampleRow
                    }
                    onDone={async (updatedSample: Sample) => {
                      await queryClient.refetchQueries({
                        queryKey: [queryKeys.RECORD_PAGE, props.type.recordId],
                      });
                      field.onChange({
                        ...value,
                        ...updatedSample,
                        score: updatedSample.result_score,
                      });
                      setEditing(false);
                    }}
                  />
                </SheetContent>
              </Sheet>
            </>
          ) : (
            <SampleSheet
              auditId={props.type.auditId}
              recordId={props.type.recordId}
              assetId={props.type.assetId}
              onDone={async (newSample: Sample) => {
                await queryClient.refetchQueries({
                  queryKey: [queryKeys.RECORD_PAGE, props.type.recordId],
                });
                field.onChange({ ...newSample, score: newSample.result_score });
              }}
            />
          )}
        </div>
        <FormMessage />
      </FormItem>
    </FormFieldContext.Provider>
  );
};

export const SampleCard = ({
  sample,
  actions,
}: {
  sample: Sample;
  actions?: { onDelete: () => void; onEdit?: () => void };
}) => {
  return (
    <div className="bg-blue-100 border border-blue-300 rounded-lg p-2">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center">
          <div
            className={`px-2 items-center rounded-lg flex flex-row space-x-2 ${
              sample.identified === "Sampled"
                ? "bg-indigo-500"
                : "bg-indigo-800"
            }`}
          >
            <Icon
              className="text-white"
              icon={
                sample.identified === "Sampled" ? "fa-microscope" : "fa-eye"
              }
            />
            <div className="text-white text-base font-semibold ">
              {sample.barcode}
            </div>
          </div>
          {/* <div className="px-2">
            <div className="font-bold text-base">{positionInstalled}</Text>
          </div> */}
        </div>
        {actions && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="tertiary" className="h-8 w-8 p-0">
                <Icon icon="fa-ellipsis-vertical" />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
              {actions.onEdit && (
                <DropdownMenuItem onSelect={actions.onEdit}>
                  Edit
                </DropdownMenuItem>
              )}
              <DropdownMenuItem onSelect={actions.onDelete}>
                Delete
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>
      <div className="flex flex-row justify-between mt-2 items-center">
        <div className="flex-1 flex-row items-center">
          {sample.identified !== "Visual assessment" && (
            <>
              <span className="text-gray-600">
                {SampleStatus[sample.status_id || 1]}
              </span>
              <span className="text-gray-500"> | </span>
            </>
          )}

          <span className="w-44">
            <span className="text-gray-600">
              {sample.result_ids &&
                sample.result_ids
                  .map((result) => SampleResult[result])
                  .join(", ")}
            </span>
          </span>
        </div>
        {/* {linkedSampleCount > 0 && (
          <View className="flex flex-row items-center space-x-1 ml-4">
            <>
              <Image
                source={images.linked_sample}
                className="h-4 w-4"
                style={{ tintColor: Colors.blue[700] }}
              />
              <Text className="text-blue-700">{linkedSampleCount}</Text>
            </>
          </View>
        )} */}
      </div>
    </div>
  );
};

export const getQuestionLabel = (report: Report) => {
  return (
    <span>
      {import.meta.env.MODE === "development" && (
        <span>{report.form_question_id} - </span>
      )}
      {report.question}
      {report.required === 1 && <span className="text-red-600"> *</span>}
    </span>
  );
};

const FormTooltip = ({ content }: { content: string }) => {
  return (
    <Tooltip>
      <TooltipTrigger asChild>
        <Icon
          icon="fa-info-circle"
          iconStyle="LIGHT"
          className="text-body-text-x-subtle"
        />
      </TooltipTrigger>
      <TooltipContent side="bottom">
        {content.split("\\n").map(function (item, index) {
          return (
            <Fragment key={index}>
              {index > 0 && (
                <>
                  <br />
                  <br />
                </>
              )}
              {item}
            </Fragment>
          );
        })}
      </TooltipContent>
    </Tooltip>
  );
};
