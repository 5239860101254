import React, { createContext, useContext, useState } from "react";

import { TBreadcrumb } from "@/types/breadcrumb-types";

type BreadcrumbContextType = {
  breadcrumbState: TBreadcrumb[];
  setBreadcrumbState: React.Dispatch<TBreadcrumb[]>;
};

const BreadcrumbContext = createContext<BreadcrumbContextType>(null!);

export const BreadcrumbProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [breadcrumbState, setBreadcrumbState] = useState<TBreadcrumb[]>([]);

  return (
    <BreadcrumbContext.Provider
      value={{
        breadcrumbState,
        setBreadcrumbState,
      }}
    >
      {children}
    </BreadcrumbContext.Provider>
  );
};

export const useBreadcrumbContext = () => {
  const context = useContext(BreadcrumbContext);
  if (context === undefined) {
    throw new Error(
      "useBreadcrumbContext must be used within a BreadcrumbProvider",
    );
  }
  return context;
};
