import { Slot } from "@radix-ui/react-slot";
import { type VariantProps, cva } from "class-variance-authority";
import * as React from "react";

import { Icon, IconProps } from "@/components/Icon";
import { cn } from "@/lib/utils";

const buttonVariants = cva(
  "inline-flex items-center border justify-center gap-2 rounded text-sm whitespace-nowrap disabled:cursor-not-allowed disabled:opacity-50 hover:shadow-sm focus:outline-none focus-visible:ring focus-visible:ring-offset-2 focus-visible:rounded-md",
  {
    variants: {
      variant: {
        primary: cn(
          "bg-button-primary-default-bg text-button-primary-default-text border-button-primary-default-border",
          "hover:bg-button-primary-hover-bg hover:text-button-primary-hover-text hover:border-button-primary-hover-border",
          "data-[active=true]:bg-button-primary-active-bg data-[active=true]:text-button-primary-active-text data-[active=true]:border-button-primary-active-border",
          "disabled:bg-button-primary-disabled-bg disabled:text-button-primary-disabled-text disabled:border-button-primary-disabled-border",
        ),
        secondary: cn(
          "bg-button-secondary-default-bg text-button-secondary-default-text border-button-secondary-default-border",
          "hover:bg-button-secondary-hover-bg hover:text-button-secondary-hover-text hover:border-button-secondary-hover-border",
          "data-[active=true]:bg-button-secondary-active-bg data-[active=true]:text-button-secondary-active-text data-[active=true]:border-button-secondary-active-border",
          "disabled:bg-button-secondary-disabled-bg disabled:text-button-secondary-disabled-text disabled:border-button-secondary-disabled-border",
        ),
        tertiary: cn(
          "bg-button-tertiary-default-bg text-button-tertiary-default-text border-button-tertiary-default-border",
          "hover:bg-button-tertiary-hover-bg hover:text-button-tertiary-hover-text hover:border-button-tertiary-hover-border",
          "data-[active=true]:bg-button-tertiary-active-bg data-[active=true]:text-button-tertiary-active-text data-[active=true]:border-button-tertiary-active-border",
          "disabled:bg-button-tertiary-disabled-bg disabled:text-button-tertiary-disabled-text disabled:border-button-tertiary-disabled-border",
        ),
        danger: cn(
          "bg-button-danger-default-bg text-button-danger-default-text border-button-danger-default-border",
          "hover:bg-button-danger-hover-bg hover:text-button-danger-hover-text hover:border-button-danger-hover-border",
          "data-[active=true]:bg-button-danger-active-bg data-[active=true]:text-button-danger-active-text data-[active=true]:border-button-danger-active-border",
          "disabled:bg-button-danger-disabled-bg disabled:text-button-danger-disabled-text disabled:border-button-danger-disabled-border",
        ),
        dangerOutline: cn(
          "bg-button-danger-outline-default-bg text-button-danger-outline-default-text border-button-danger-outline-default-border",
          "hover:bg-button-danger-outline-hover-bg hover:text-button-danger-outline-hover-text hover:border-button-danger-outline-hover-border",
          "data-[active=true]:bg-button-danger-outline-active-bg data-[active=true]:text-button-danger-outline-active-text data-[active=true]:border-button-danger-outline-active-border",
          "disabled:bg-button-danger-outline-disabled-bg disabled:text-button-danger-outline-disabled-text disabled:border-button-danger-outline-disabled-border",
        ),
      },
      size: {
        default: "h-8 px-3",
        lg: "h-10 rounded-md px-8",
        icon: "h-8 w-8",
      },
    },
    defaultVariants: {
      variant: "primary",
      size: "default",
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

type ButtonIconProps = ButtonProps & IconProps;

interface IconButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  icon: IconProps["icon"];
  iconStyle?: IconProps["iconStyle"];
  variant?: ButtonProps["variant"];
  size?: ButtonProps["size"];
  asChild?: ButtonProps["asChild"];
}

const IconButton = React.forwardRef<HTMLButtonElement, IconButtonProps>(
  ({ icon, iconStyle, size = "icon", ...props }, ref) => {
    return (
      <Button
        {...props}
        ref={ref}
        size={size}
        className={`p-0 ${props.className || ""}`}
      >
        <ButtonIcon icon={icon} iconStyle={iconStyle} />
      </Button>
    );
  },
);

IconButton.displayName = "IconButton";

const ButtonIconLeft = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ icon, iconStyle, className, children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className={`pl-2 pr-3 ${className}`}>
        <ButtonIcon icon={icon} iconStyle={iconStyle} /> {children}
      </Button>
    );
  },
);

ButtonIconLeft.displayName = "ButtonIconLeft";

const ButtonIconRight = React.forwardRef<HTMLButtonElement, ButtonIconProps>(
  ({ icon, iconStyle, className, children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className="pl-3 pr-2">
        {children} <ButtonIcon icon={icon} iconStyle={iconStyle} />
      </Button>
    );
  },
);

ButtonIconRight.displayName = "ButtonIconRight";

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, ...props }, ref) => {
    const Comp = asChild ? Slot : "button";
    return (
      <Comp
        className={cn(buttonVariants({ variant, size, className }))}
        ref={ref}
        {...props}
      />
    );
  },
);
Button.displayName = "Button";

const ButtonIcon = React.forwardRef<HTMLDivElement, ButtonIconProps>(
  ({ icon, iconStyle, ...props }, ref) => {
    return <Icon icon={icon} iconStyle={iconStyle} ref={ref} {...props} />;
  },
);
ButtonIcon.displayName = "ButtonIcon";

const DropdownButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className="gap-0 px-0">
        <span className="px-3">{children}</span>{" "}
        <ButtonIcon
          icon={"fa-chevron-down"}
          iconStyle={"REGULAR"}
          className="pr-2.5"
        />
      </Button>
    );
  },
);

DropdownButton.displayName = "DropdownButton";

const DropdownIconOnly = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, ...props }, ref) => {
    return (
      <Button {...props} ref={ref} className="gap-0 px-0">
        <span className="h-8 w-8 inline-flex items-center justify-center">
          {children}
        </span>{" "}
        <ButtonIcon
          icon={"fa-chevron-down"}
          iconStyle={"REGULAR"}
          className="pr-2.5"
        />
      </Button>
    );
  },
);

DropdownIconOnly.displayName = "DropdownIconOnly";

const DropdownButtonIconLeft = React.forwardRef<
  HTMLButtonElement,
  ButtonIconProps
>(({ icon, iconStyle, children, ...props }, ref) => {
  return (
    <Button {...props} ref={ref} className="px-0">
      <ButtonIcon icon={icon} iconStyle={iconStyle} className="ml-2" />{" "}
      <span className="pr-1">{children}</span>{" "}
      <ButtonIcon
        icon={"fa-chevron-down"}
        iconStyle={"REGULAR"}
        className="pr-2.5"
      />
    </Button>
  );
});

DropdownButtonIconLeft.displayName = "DropdownButtonIconLeft";

export {
  Button,
  DropdownButton,
  ButtonIconLeft,
  ButtonIconRight,
  IconButton,
  DropdownIconOnly,
  DropdownButtonIconLeft,
  ButtonIcon,
  buttonVariants,
};
