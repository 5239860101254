import { useQuery } from "@tanstack/react-query";
import axios from "axios";

import { queryKeys } from "@/lib/constants";
import {
  Audit,
  Sample,
  SampleIdentification,
  SampleResultGroup,
  SampleRow,
} from "@/types";

export const useSamplesByAudit = ({
  auditId,
  identified,
}: {
  auditId?: Audit["audit_id"];
  identified?: SampleIdentification;
}) => {
  return useQuery({
    queryKey: [queryKeys.ALL_SAMPLES, auditId, identified],
    queryFn: async () => {
      if (!auditId) {
        return [];
      }
      const response = await axios.get<{ data: Sample[] }>(
        route("api.audits.samples.all", { audit: auditId }),
      );
      const allSamples = response.data.data;

      if (!identified) {
        return allSamples;
      }

      const filtered = allSamples.filter((s) => s.identified === identified);
      return filtered;
    },
  });
};

export const useParentSamplesByAudit = ({
  auditId,
}: {
  auditId: Audit["audit_id"];
}) => {
  const { data: physicalSamples } = useSamplesByAudit({
    auditId,
    identified: "Sampled",
  });
  return physicalSamples?.filter((s) => !s.parent_id);
};

export const useSampleById = ({
  sampleId,
}: {
  sampleId?: SampleRow["as_sampleids"];
}) => {
  return useQuery({
    queryKey: [queryKeys.SAMPLE, sampleId],
    enabled: !!sampleId,
    queryFn: async () => {
      const response = await axios.get<{ data: Sample }>(
        route("api.samples.show", { sample: sampleId }),
      );
      return response.data.data;
    },
  });
};

export const useSampleResultOptions = () => {
  return useQuery({
    queryKey: [queryKeys.SAMPLE_RESULT_OPTIONS],
    queryFn: async () => {
      const response = await axios.get<SampleResultGroup[]>(
        route("api.samples.result-options"),
      );
      return response.data;
    },
  });
};
