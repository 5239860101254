// import "./lib/wdyr";
import { createInertiaApp } from "@inertiajs/react";
import { resolvePageComponent } from "laravel-vite-plugin/inertia-helpers";
import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { withErrorBoundary } from "react-error-boundary";

import { ErrorComponent } from "@/components/Error";
import { TooltipProvider } from "@/components/ui";
import AuthenticatedLayout from "@/layout/Authenticated";
import { AppProvider } from "@/providers/AppProvider";
import { BreadcrumbProvider } from "@/providers/BreadcrumbProvider";
import { TableServiceProvider } from "@/providers/TableServiceProvider";

import * as Sentry from "@sentry/react";

import "../css/v4.css";
import "./bootstrap";
import { ThemeProvider } from "./providers/ThemeProvider";
import { TODO } from "./types";

import.meta.glob(["../images/**"]);

const moduleLayouts: Record<string, TODO> = import.meta.glob(
  "./../../Modules/**/resources/js/layout.tsx",
  { eager: true },
);

const namedModuleLayouts: TODO = Object.keys(moduleLayouts).reduce(
  (acc, path) => {
    const moduleName = path.split("/")[3]; // Adjust the index based on your actual path structure

    // Use the module name as the key and the module itself as the value
    acc[moduleName] = moduleLayouts[path].default; // Assuming you want the default export

    return acc;
  },
  {} as TODO,
);

const posthogOptions: { apiKey: string; options: Partial<PostHogConfig> } = {
  apiKey: import.meta.env.VITE_REACT_APP_POSTHOG_KEY,
  options: {
    api_host: "/api/posthog",
  },
};

const appName = import.meta.env.VITE_APP_NAME || "Octfolio";
const resolvePage = async (name: string) => {
  // Check if the name is a module
  const isModule = name.split("::");
  if (isModule.length > 1) {
    // Import pages from the modules folder
    const pages = import.meta.glob(
      `./../../Modules/**/resources/js/pages/**/*.tsx`,
    );

    // Sort and replace the wrong slash and dot with the correct slash
    const path = Object.keys(pages)
      .sort((a, b) => a.length - b.length)
      .find((path) =>
        path.endsWith(
          `${isModule[1].replaceAll("\\", "/").replaceAll(".", "/")}.tsx`,
        ),
      );
    // Throw an error if the page is not found
    if (!path) {
      throw new Error(
        `Page not found: ${isModule[1]} in module ${isModule[0]}`,
      );
    }

    return [await pages[path](), isModule[0]];
  }

  const page = await resolvePageComponent(
    `./pages/${name}.tsx`,
    import.meta.glob("./pages/**/*.tsx"),
  );

  return [page, false];
};

createInertiaApp({
  title: (title) => (title ? `${title} - ${appName}` : appName),
  resolve: async (name) => {
    const [page, moduleName]: TODO = await resolvePage(name);

    if (moduleName) {
      page.default.layout = (page: React.ReactNode) => {
        const LayoutComp = namedModuleLayouts[moduleName];
        return <LayoutComp>{page}</LayoutComp>;
      };
      return page;
    }

    page.default.layout = (page: React.ReactNode) => (
      <AuthenticatedLayout>{page}</AuthenticatedLayout>
    );

    const standalonePages = ["Login", "SearchPage", "ErrorPage"];
    if (standalonePages.includes(name)) {
      page.default.layout = (page: React.ReactNode) => page;
    }

    return page;
  },
  setup({ el, App, props }) {
    const root = createRoot(el);

    Sentry.init({
      // @ts-expect-error: props.initialPage.props.platform' is of type 'unknown
      release: props.initialPage.props.platform.version as string,
      dsn: "https://30e3939ef1a6b755bc538fa14dd0fcd2@o1155666.ingest.sentry.io/4506273833222144",
      enabled: import.meta.env.PROD,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 0.2,
      replaysSessionSampleRate: 0.2,
      replaysOnErrorSampleRate: 1.0,
    });

    const Application = () => {
      return (
        <PostHogProvider {...posthogOptions}>
          <ThemeProvider>
            <TooltipProvider>
              <AppProvider>
                <BreadcrumbProvider>
                  <TableServiceProvider>
                    <App {...props} />
                  </TableServiceProvider>
                </BreadcrumbProvider>
              </AppProvider>
            </TooltipProvider>
          </ThemeProvider>
        </PostHogProvider>
      );
    };

    const ComponentWithErrorBoundary = withErrorBoundary(Application, {
      fallback: <ErrorComponent />,
      onError(error, info) {
        console.error("Error encountered", error, info);
      },
    });

    root.render(
      <StrictMode>
        <ComponentWithErrorBoundary {...props} />
      </StrictMode>,
    );
  },
  progress: {
    delay: 500,
    color: "#0D5AF2",
    showSpinner: false,
  },
});
