export const config = {
  INTERCOM_APP_ID: "nxzgj6st",
  MAX_MB_UPLOAD: {
    DEFAULT: 20,
    DOCUMENT: 200,
    IMAGE: 50,
    SIGNATURE: 2,
  },
};

export const defaultErrorMessage = "Oops! Something went wrong";

export const queryKeys = {
  ALL_SAMPLES: "ALL_SAMPLES",
  USER_CURRENT_WAITLISTS: "USER_CURRENT_WAITLISTS",
  SAMPLE: "SAMPLE",
  SAMPLE_RESULT_OPTIONS: "SAMPLE_RESULT_OPTIONS",
  RECORD_PAGE: "RECORD_PAGE",
  RECORD_PAGE_SIDEBAR: "RECORD_PAGE_SIDEBAR",
};

export const localStorageKeys = {
  LOCK_SCREEN_UNLOCKED: "lock-screen-unlocked",
  LOCK_SCREEN_LOGOUT: "lock-screen-logout",
  SESSION_EXPIRES_AT: "session_expires_at",
};
